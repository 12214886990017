import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiUrls, get, post, put } from 'utils/request';
import moment from 'moment';
import { toast } from 'react-toastify';
import { thousandSeparator } from 'utils/helper';

export const initialState = {
  mdPit: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  mdContractor: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  mdPort: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  mdRom: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  mdFloatingCrane: {
    records: [],
    loading: false,
    error: null,
    recordsTotal: "",
  },
  productionList: {
    records: {},
    loading: false,
    error: null,
  },
  pitToPortList: {
    records: [],
    loading: false,
    error: null,
  },
  pitToRomList: {
    records: [],
    loading: false,
    error: null,
  },
  romToPortList: {
    records: [],
    loading: false,
    error: null,
  },
  coalToMineList: {
    records: [],
    loading: false,
    error: null,
  },
  fleetFromPitList: {
    records: [],
    loading: false,
    error: null,
  },
  haulingFleet: {
    records: null,
    loading: false,
    error: null,
  },
  planCoalFleet: {
    records: [],
    loading: false,
    error: null,
  },
  stockpileList: {
    records: [],
    loading: false,
    error: null,
  },
  stockpileRomList: {
    records: [],
    loading: false,
    error: null,
  },
  stockpilePortList: {
    records: [],
    loading: false,
    error: null,
  },
  // weather
  weatherActual: {
    records: {},
    loading: false,
    error: null
  },
  weatherActualList: {
    records: [],
    loading: false,
    error: null
  },
  weatherActualDetail: {
    records: [],
    loading: false,
    error: null
  },
  fileUpload: {
    records: {},
    loading: false,
    error: null
  },
  weatherForecast: {
    records: null,
    loading: false,
    error: null
  },
  bargingList: {
    records: {},
    loading: false,
    error: null,
  },
  bargingPlan: {
    records: null,
    loading: false,
    error: null,
  },
  bargingPlanDetail: {
    records: [],
    loading: false,
    error: null,
  },
  bargingActual: {
    records: null,
    loading: false,
    error: null,
  },
  bargingActualDetail: {
    records: [],
    loading: false,
    error: null,
  },
  // barging status and load progress
  bargingStatus: {
    records: null,
    loading: false,
    error: null,
  },
  bargingProgressLoad: {
    records: [],
    loading: false,
    error: null,
  },
  // barging forecast
  bargingForecast: {
    records: [],
    loading: false,
    error: null,
  },

  // transshipment
  transshipmentList: {
    records: {},
    loading: false,
    error: null,
  },
  // transshipment actual
  transshipmentActualBefore: {
    records: null,
    loading: false,
    error: null,
  },
  transshipmentActual: {
    records: null,
    loading: false,
    error: null,
  },
  transshipmentActualInRange: {
    records: [],
    loading: false,
    error: null,
  },
  transshipmentActualDetailBefore: {
    records: [],
    loading: false,
    error: null,
  },
  transshipmentActualDetail: {
    records: [],
    loading: false,
    error: null,
  },
  // transshipment today
  transshipmentNextList: {
    records: [],
    loading: false,
    error: null,
  },
  // transshipment floating crane
  transshipmentFloatingCraneList: {
    records: [],
    loading: false,
    error: null,
  },
  // transshipment forecast
  transshipmentForecast: {
    records: [],
    loading: false,
    error: null,
  },
}

export const yesterdayDate = moment().subtract(1, 'days').format("YYYY-MM-DD");

export const getMDPit = createAsyncThunk(
  'executiv-report/activity/production/pit/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/masterData/pit`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });
    return response?.data;
  }
);

export const getMDContractor = createAsyncThunk(
  'executiv-report/activity/production/contractor/list',
  async (param) => {
    const { location_id } = param
    const response = await get(`${apiUrls.executiveReport}/masterData/contractor?${location_id ? 'location_id=' + location_id : ''}`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });
    return response?.data;
  }
);

export const getMDPort = createAsyncThunk(
  'executiv-report/activity/production/port/list',
  async (param) => {
    const { contractor_id } = param
    const response = await get(`${apiUrls.executiveReport}/masterData/port?${contractor_id ? 'contractor_id=' + contractor_id : ''}`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });
    return response?.data;
  }
);

export const getMDRom = createAsyncThunk(
  'executiv-report/activity/production/rom/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/masterData/rom`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });

    return response?.data;
  }
);

export const getMDFloatingCrane = createAsyncThunk(
  'executiv-report/activity/production/floating-crane/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/masterData/floating-crane`).catch((e) => {
      if (e.data.error) {
        toast.error(e.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
    });

    return response?.data;
  }
);

export const getProductionList = createAsyncThunk(
  'executiv-report/activity/production/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/production?worked_at=${yesterdayDate}`);

    return response?.data;
  }
);

export const getPitToPortList = createAsyncThunk(
  'executiv-report/activity/production/pit-to-port',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/pit-to-port?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreatePitToPort = createAsyncThunk(
  'executiv-report/activity/production/pit-to-port/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/pit-to-port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdatePitToPort = createAsyncThunk(
  'executiv-report/activity/production/pit-to-port/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/pit-to-port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeletePitToPort = createAsyncThunk(
  'executiv-report/activity/production/pit-to-port/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/pit-to-port/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getPitToRomList = createAsyncThunk(
  'executiv-report/activity/production/pit-to-rom',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/pit-to-rom?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);


export const handleCreatePitToRom = createAsyncThunk(
  'executiv-report/activity/production/pit-to-rom/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/pit-to-rom`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdatePitToRom = createAsyncThunk(
  'executiv-report/activity/production/pit-to-rom/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/pit-to-rom`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeletePitToRom = createAsyncThunk(
  'executiv-report/activity/production/pit-to-rom/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/pit-to-rom/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getRomToPortList = createAsyncThunk(
  'executiv-report/activity/production/rom-to-port',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/rom-to-port?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);


export const handleCreateRomToPort = createAsyncThunk(
  'executiv-report/activity/production/rom-to-port/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/rom-to-port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateRomToPort = createAsyncThunk(
  'executiv-report/activity/production/rom-to-port/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/rom-to-port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteRomToPort = createAsyncThunk(
  'executiv-report/activity/production/rom-to-port/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/rom-to-port/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getCoalToMineList = createAsyncThunk(
  'executiv-report/activity/production/coal-to-mine',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/coal-to-mine?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateCoalToMine = createAsyncThunk(
  'executiv-report/activity/production/coal-to-mine/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/coal-to-mine`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateCoalToMine = createAsyncThunk(
  'executiv-report/activity/production/coal-to-mine/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/coal-to-mine`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteCoalToMine = createAsyncThunk(
  'executiv-report/activity/production/coal-to-mine/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/coal-to-mine/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);


// actual coal fleet
// fleet from pit
export const getFleetFromPitList = createAsyncThunk(
  'executiv-report/activity/production/fleet-from-pit',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/fleet-from-pit?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateFleetFromPit = createAsyncThunk(
  'executiv-report/activity/production/fleet-from-pit/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/fleet-from-pit`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateFleetFromPit = createAsyncThunk(
  'executiv-report/activity/production/fleet-from-pit/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/fleet-from-pit`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteFleetFromPit = createAsyncThunk(
  'executiv-report/activity/production/fleet-from-pit/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/fleet-from-pit/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);
// hauling fleet
export const getHaulingFleet = createAsyncThunk(
  'executiv-report/activity/production/hauling-fleet',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/hauling-fleet?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateHaulingFleet = createAsyncThunk(
  'executiv-report/activity/production/hauling-fleet/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/hauling-fleet`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateHaulingFleet = createAsyncThunk(
  'executiv-report/activity/production/hauling-fleet/update',
  (param) => {
    const { object, id } = param
    if (object.length < 1 || !id) return;
    try {
      return put(`${apiUrls.executiveReport}/production/hauling-fleet/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteHaulingFleet = createAsyncThunk(
  'executiv-report/activity/production/hauling-fleet/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/hauling-fleet/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// plan coal fleet
export const getPlanCoalFleet = createAsyncThunk(
  'executiv-report/activity/production/plan-coal-fleet/list',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/plan-coal-fleet?worked_at=${param?.date ?? yesterdayDate}`);
    return response?.data;
  }
);

export const handleCreatePlanCoalFleet = createAsyncThunk(
  'executiv-report/activity/production/plan-coal-fleet/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/plan-coal-fleet`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdatePlanCoalFleet = createAsyncThunk(
  'executiv-report/activity/production/plan-coal-fleet/update',
  (param) => {
    const { object, id } = param
    if (object.length < 1 || !id) return;
    try {
      return put(`${apiUrls.executiveReport}/production/plan-coal-fleet/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);


export const getStockpileList = createAsyncThunk(
  'executiv-report/activity/stockpile/list',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/stockpile?worked_at=${param?.date ?? yesterdayDate}`);
    return response?.data;
  }
);

export const getStockpileRomList = createAsyncThunk(
  'executiv-report/activity/stockpile/rom',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/stockpile/rom?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateStockpileRom = createAsyncThunk(
  'executiv-report/activity/stockpile/rom/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/stockpile/rom`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateStockpileRom = createAsyncThunk(
  'executiv-report/activity/stockpile/rom/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/stockpile/rom`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteStockpileRom = createAsyncThunk(
  'executiv-report/activity/stockpile/rom/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/stockpile/rom/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getStockpilePortList = createAsyncThunk(
  'executiv-report/activity/stockpile/port',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/stockpile/port?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateStockpilePort = createAsyncThunk(
  'executiv-report/activity/stockpile/port/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/stockpile/port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateStockpilePort = createAsyncThunk(
  'executiv-report/activity/stockpile/port/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/stockpile/port`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteStockpilePort = createAsyncThunk(
  'executiv-report/activity/stockpile/port/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/stockpile/port/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);
// Barging
export const getBargingList = createAsyncThunk(
  'executiv-report/activity/barging/list',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/barging?worked_at=${yesterdayDate}`);

    return response?.data;
  }
);

// Barging plan
export const getBargingPlan = createAsyncThunk(
  'executiv-report/activity/barging/plan',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/barging/plan?worked_at=${yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingPlan = createAsyncThunk(
  'executiv-report/activity/barging/plan/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/plan`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingPlan = createAsyncThunk(
  'executiv-report/activity/barging/plan/update',
  (param) => {
    const { object, plan_id } = param
    if (object.length < 1 || !plan_id) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/plan/${plan_id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getBargingPlanDetailList = createAsyncThunk(
  'executiv-report/activity/barging/plan-detail',
  async (param) => {
    const { plan_id } = param
    const response = await get(`${apiUrls.executiveReport}/barging/plan-detail?plan_id=${plan_id}`);

    return response?.data;
  }
);

export const handleCreateBargingPlanDetail = createAsyncThunk(
  'executiv-report/activity/barging/plan-detail/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/plan-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingPlanDetail = createAsyncThunk(
  'executiv-report/activity/barging/plan-detail/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/plan-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

// barging actual
export const getBargingActual = createAsyncThunk(
  'executiv-report/activity/barging/actual',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/barging/actual?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingActual = createAsyncThunk(
  'executiv-report/activity/barging/actual/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/actual`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingActual = createAsyncThunk(
  'executiv-report/activity/barging/actual/update',
  (param) => {
    const { object, actual_id } = param
    if (object.length < 1 || !actual_id) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/actual/${actual_id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getBargingActualDetailList = createAsyncThunk(
  'executiv-report/activity/barging/actual-detail',
  async (param) => {
    const { actual_id } = param
    const response = await get(`${apiUrls.executiveReport}/barging/actual-detail?actual_id=${actual_id}`);

    return response?.data;
  }
);

export const handleCreateBargingActualDetail = createAsyncThunk(
  'executiv-report/activity/barging/actual-detail/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingActualDetail = createAsyncThunk(
  'executiv-report/activity/barging/actual-detail/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

// barging status
export const getBargingStatusList = createAsyncThunk(
  'executiv-report/activity/barging/barge-status',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/barging/barge-status?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingStatus = createAsyncThunk(
  'executiv-report/activity/barging/barge-status/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/barge-status`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingStatus = createAsyncThunk(
  'executiv-report/activity/barging/barge-status/update',
  (param) => {
    const { object, id } = param
    if (object.length < 1 || !id) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/barge-status/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

// barging load progress
export const getBargingProgressLoadList = createAsyncThunk(
  'executiv-report/activity/barging/barge-progress-load',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/barging/barge-progress-load?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingProgressLoad = createAsyncThunk(
  'executiv-report/activity/barging/barge-progress-load/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/barge-progress-load`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingProgressLoad = createAsyncThunk(
  'executiv-report/activity/barging/barge-progress-load/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/barge-progress-load`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

// barging forecast
export const getBargingForecastList = createAsyncThunk(
  'executiv-report/activity/barging/forecast',
  async () => {
    const response = await get(`${apiUrls.executiveReport}/barging/forecast?worked_at=${yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateBargingForecast = createAsyncThunk(
  'executiv-report/activity/barging/forecast/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateBargingForecast = createAsyncThunk(
  'executiv-report/activity/barging/forecast/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/barging/forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteBargingForecast = createAsyncThunk(
  'executiv-report/activity/barging/forecast/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/barging/forecast/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);


// weather
export const getWeatherActual = createAsyncThunk(
  'executive-report/activity/production/weather-actual',
  async (params) => {
    const response = await get(`${apiUrls.executiveReport}/production/weather-actual/${params.id}`);

    return response?.data;
  }
);

export const getWeatherActualList = createAsyncThunk(
  'executive-report/activity/production/weather-actual-list',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/weather-actual?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateWeatherActual = createAsyncThunk(
  'executive-report/activity/production/weather-actual/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-actual`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateWeatherActual = createAsyncThunk(
  'executive-report/activity/production/weather-actual/update',
  (param) => {
    const { id, object } = param
    if (!id || !object) return;
    try {
      return put(`${apiUrls.executiveReport}/production/weather-actual/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteWeatherActual = createAsyncThunk(
  'executive-report/activity/production/weather-actual/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-actual/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getWeatherActualDetailList = createAsyncThunk(
  'executive-report/activity/production/weather-actual-detail',
  async (params) => {
    const { weather_actual_id } = params
    const response = await get(`${apiUrls.executiveReport}/production/weather-actual-detail?weather_actual_id=${weather_actual_id}`);

    return response?.data;
  }
);

export const handleCreateWeatherActualDetail = createAsyncThunk(
  'executive-report/activity/production/weather-actual-detail/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateWeatherActualDetail = createAsyncThunk(
  'executive-report/activity/production/weather-actual-detail/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/production/weather-actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteWeatherActualDetail = createAsyncThunk(
  'executive-report/activity/production/weather-actual-detail/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-actual-detail/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleFileUpload = createAsyncThunk(
  'executiv-report/activity/file/upload',
  async (param) => {
    if (!param) return;
    const requestOptions = {
      method: "POST",
      body: param,
      'Content-Type': 'multipart/form-data'
    };
    try {
      const fetchResponse = await fetch(`${apiUrls.executiveReport}/file/upload`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          return result
        })
        .catch((e) => {
          return e;
        });
      return fetchResponse;
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getWeatherForecast = createAsyncThunk(
  'executive-report/activity/production/weather-forecast',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/production/weather-forecast?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateWeatherForecast = createAsyncThunk(
  'executive-report/activity/production/weather-forecast/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/production/weather-forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateWeatherForecast = createAsyncThunk(
  'executive-report/activity/production/weather-forecast/update',
  (param) => {
    const { id, object } = param
    if (!id || !object) return;
    try {
      return put(`${apiUrls.executiveReport}/production/weather-forecast/${id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);


// Transshipment
export const getTransshipmentList = createAsyncThunk(
  'executiv-report/activity/transshipment/list',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

// transshipment actual
export const getTransshipmentActual = createAsyncThunk(
  'executiv-report/activity/transshipment/actual',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);
export const getTransshipmentActualInRange = createAsyncThunk(
  'executiv-report/activity/transshipment/actual-in-range',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual?start_worked_at=${param?.start_worked_at ?? yesterdayDate}&end_worked_at=${param?.end_worked_at ?? yesterdayDate}`);

    return response?.data;
  }
);
// get yeserday report data
export const getTransshipmentActualBeforeReportDate = createAsyncThunk(
  'executiv-report/activity/transshipment/actual/before',
  async (param) => {
    let dt = new Date(param?.date ?? yesterdayDate);
    const newDt = moment(dt).subtract(1, 'days').format("YYYY-MM-DD");

    const response = await get(`${apiUrls.executiveReport}/transshipment/actual?worked_at=${newDt}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentActual = createAsyncThunk(
  'executiv-report/activity/transshipment/actual/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/actual`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentActual = createAsyncThunk(
  'executiv-report/activity/transshipment/actual/update',
  (param) => {
    const { object, actual_id } = param
    if (object.length < 1 || !actual_id) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/actual/${actual_id}`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentActual = createAsyncThunk(
  'executiv-report/activity/transshipment/actual/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/actual/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const getTransshipmentActualDetailListBeforeReportDate = createAsyncThunk(
  'executiv-report/activity/transshipment/actual-detail/before',
  async (param) => {
    const { actual_id } = param
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual-detail?transshipment_actual_id=${actual_id}`);

    return response?.data;
  }
);


export const getTransshipmentActualDetailList = createAsyncThunk(
  'executiv-report/activity/transshipment/actual-detail',
  async (param) => {
    const { actual_id } = param
    const response = await get(`${apiUrls.executiveReport}/transshipment/actual-detail?transshipment_actual_id=${actual_id}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentActualDetail = createAsyncThunk(
  'executiv-report/activity/transshipment/actual-detail/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentActualDetail = createAsyncThunk(
  'executiv-report/activity/transshipment/actual-detail/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/actual-detail`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentActualDetail = createAsyncThunk(
  'executiv-report/activity/transshipment/actual-detail/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/actual-detail/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// transshipment today
export const getTransshipmentNextList = createAsyncThunk(
  'executiv-report/activity/transshipment/next',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/next?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentNext = createAsyncThunk(
  'executiv-report/activity/transshipment/next/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/next`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentNext = createAsyncThunk(
  'executiv-report/activity/transshipment/next/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/next`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentNext = createAsyncThunk(
  'executiv-report/activity/transshipment/next/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/next/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// transshipment floating crane
export const getTransshipmentFloatingCraneList = createAsyncThunk(
  'executiv-report/activity/transshipment/floating-crane',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/floating-crane?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentFloatingCrane = createAsyncThunk(
  'executiv-report/activity/transshipment/floating-crane/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/floating-crane`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentFloatingCrane = createAsyncThunk(
  'executiv-report/activity/transshipment/floating-crane/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/floating-crane`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentFloatingCrane = createAsyncThunk(
  'executiv-report/activity/transshipment/floating-crane/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/floating-crane/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

// transshipment forecast
export const getTransshipmentForecastList = createAsyncThunk(
  'executiv-report/activity/transshipment/forecast',
  async (param) => {
    const response = await get(`${apiUrls.executiveReport}/transshipment/forecast?worked_at=${param?.date ?? yesterdayDate}`);

    return response?.data;
  }
);

export const handleCreateTransshipmentForecast = createAsyncThunk(
  'executiv-report/activity/transshipment/forecast/create',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleUpdateTransshipmentForecast = createAsyncThunk(
  'executiv-report/activity/transshipment/forecast/update',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return put(`${apiUrls.executiveReport}/transshipment/forecast`, object).catch((e) => {
        return e.data;
      });
    }
    catch (e) {
      return e.data;
    }
  }
);

export const handleDeleteTransshipmentForecast = createAsyncThunk(
  'executiv-report/activity/transshipment/forecast/delete',
  (param) => {
    const { object } = param
    if (object.length < 1) return;
    try {
      return post(`${apiUrls.executiveReport}/transshipment/forecast/delete`, { ids: object });
    }
    catch (e) {
      return e.data;
    }
  }
);

const executiveReportSlice = createSlice({
  name: 'EXECUTIVE_REPORT_REDUCER',
  initialState,
  reducers: {
    onChangeStockpileRomList(state, action) {
      const {
        idItem,
        indexItem,
        updatedKey,
        updatedValue
      } = action.payload;

      const updateItem = state.stockpileRomList.records.map((item, index) => {
        if ((idItem !== undefined && item.id === idItem) || (idItem === undefined && index === indexItem)) {
          return {
            ...item,
            [updatedKey]: updatedValue
          };
        }

        return item;
      });

      console.log('😒 ♾️ file: reducer.js:1543 ♾️ onChangeStockpileRomList:', action);
      state.stockpileRomList.records = updateItem;
    },
    onChangeStockpilePortList(state, action) {
      const {
        idItem,
        indexItem,
        updatedKey,
        updatedValue
      } = action.payload;

      const updateItem = state.stockpilePortList.records.map((item, index) => {
        if ((idItem !== undefined && item.id === idItem) || (idItem === undefined && index === indexItem)) {
          return {
            ...item,
            [updatedKey]: updatedValue
          };
        }

        return item;
      });

      console.log('😒 ♾️ file: reducer.js:1547 ♾️ onChangeStockpilePortList:', action);
      state.stockpilePortList.records = updateItem;
    },
    onAddStockpileRomList(state, action) {
      const newData = {
        "rom": null,
        "upper_bed": null,
        "stock": null,
        "notes": '',
        "worked_at": yesterdayDate
      }

      state.stockpileRomList.records.push(newData);
      console.log('😒 ♾️ file: reducer.js:1551 ♾️ onAddStockpileRomList:', action);
      // state.stockpileRomList.push(action.payload);
    },
    onAddStockpilePortList(state, action) {
      const newData = {
        "calorie_type": null,
        "port": null,
        "rc": null,
        "cc": null,
        "gantungan": null,
        "notes": '',
        "worked_at": yesterdayDate
      }

      state.stockpilePortList.records.push(newData);
      console.log('😒 ♾️ file: reducer.js:1555 ♾️ onAddStockpilePortList:', action);
      // state.stockpilePortList.push(action.payload);
    },
    onDelStockpileRomList(state, action) {
      const { idItem, indexItem } = action.payload;

      console.log('😒 ♾️ file: reducer.js:1559 ♾️ onDelStockpileRomList:', action);
      state.stockpileRomList.records = state.stockpileRomList.records.filter((item, index) => (item.id !== idItem) || (index !== indexItem));
    },
    onDelStockpilePortList(state, action) {
      const { idItem, indexItem } = action.payload;

      console.log('😒 ♾️ file: reducer.js:1563 ♾️ onDelStockpilePortList:', action);
      state.stockpilePortList.records = state.stockpilePortList.records.filter((item, index) => (item.id !== idItem) || (index !== indexItem));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMDPit.pending, (state, action) => {
      state.mdPit.loading = true;
    });
    builder.addCase(getMDPit.fulfilled, (state, action) => {
      state.mdPit.records = action.payload;
      state.mdPit.loading = false;
    });
    builder.addCase(getMDPit.rejected, (state, action) => {
      state.mdPit.loading = false;
      state.mdPit.error = 'Invalid get data';
      state.mdPit.records = [];
    });
    builder.addCase(getMDContractor.pending, (state, action) => {
      state.mdContractor.loading = true;
    });
    builder.addCase(getMDContractor.fulfilled, (state, action) => {
      state.mdContractor.records = action.payload;
      state.mdContractor.loading = false;
    });
    builder.addCase(getMDContractor.rejected, (state, action) => {
      state.mdContractor.loading = false;
      state.mdContractor.error = 'Invalid get data';
      state.mdContractor.records = [];
    });
    builder.addCase(getMDPort.pending, (state, action) => {
      state.mdPort.loading = true;
    });
    builder.addCase(getMDPort.fulfilled, (state, action) => {
      state.mdPort.records = action.payload;
      state.mdPort.loading = false;
    });
    builder.addCase(getMDPort.rejected, (state, action) => {
      state.mdPort.loading = false;
      state.mdPort.error = 'Invalid get data';
      state.mdPort.records = [];
    });
    builder.addCase(getMDRom.pending, (state, action) => {
      state.mdRom.loading = true;
    });
    builder.addCase(getMDRom.fulfilled, (state, action) => {
      state.mdRom.records = action.payload;
      state.mdRom.loading = false;
    });
    builder.addCase(getMDRom.rejected, (state, action) => {
      state.mdRom.loading = false;
      state.mdRom.error = 'Invalid get data';
      state.mdRom.records = [];
    });
    builder.addCase(getMDFloatingCrane.pending, (state, action) => {
      state.mdFloatingCrane.loading = true;
    });
    builder.addCase(getMDFloatingCrane.fulfilled, (state, action) => {
      state.mdFloatingCrane.records = action.payload;
      state.mdFloatingCrane.loading = false;
    });
    builder.addCase(getMDFloatingCrane.rejected, (state, action) => {
      state.mdFloatingCrane.loading = false;
      state.mdFloatingCrane.error = 'Invalid get data';
      state.mdFloatingCrane.records = [];
    });
    builder.addCase(getProductionList.pending, (state, action) => {
      state.productionList.loading = true;
    });
    builder.addCase(getProductionList.fulfilled, (state, action) => {
      state.productionList.records = action.payload;
      state.productionList.loading = false;
    });
    builder.addCase(getProductionList.rejected, (state, action) => {
      state.productionList.loading = false;
    });
    builder.addCase(getPitToPortList.pending, (state, action) => {
      state.pitToPortList.loading = true;
    });
    builder.addCase(getPitToPortList.fulfilled, (state, action) => {
      const ptpList = action.payload || [];
      let pitcontractorUnique = [];
      ptpList.forEach(function (v) {
        const findPitContractorUniqueIndex = pitcontractorUnique.findIndex((vFind) => vFind.pit === v.pit && vFind.contractor === v.contractor)
        if (findPitContractorUniqueIndex > -1) {
          pitcontractorUnique[findPitContractorUniqueIndex].port.push({
            id: v.id,
            port: v.port,
            tonase: thousandSeparator(v.tonase)
          })
        } else {
          pitcontractorUnique.push({
            pit: v.pit,
            contractor: v.contractor,
            port: [
              {
                id: v.id,
                port: v.port,
                tonase: thousandSeparator(v.tonase)
              }
            ]
          });
        }
      });
      state.pitToPortList.records = pitcontractorUnique;
      state.pitToPortList.loading = false;
    });
    builder.addCase(getPitToPortList.rejected, (state, action) => {
      state.pitToPortList.loading = false;
    });

    builder.addCase(getPitToRomList.pending, (state, action) => {
      state.pitToRomList.loading = true;
    });
    builder.addCase(getPitToRomList.fulfilled, (state, action) => {
      const ptr = action.payload || [];
      let pitcontractorUnique = [];
      ptr.forEach(function (v) {
        const findPitContractorUniqueIndex = pitcontractorUnique.findIndex((vFind) => vFind.pit === v.pit && vFind.contractor === v.contractor)
        if (findPitContractorUniqueIndex > -1) {
          pitcontractorUnique[findPitContractorUniqueIndex].rom.push({
            id: v.id,
            rom: v.rom,
            tonase: thousandSeparator(v.tonase)
          })
        } else {
          pitcontractorUnique.push({
            pit: v.pit,
            contractor: v.contractor,
            rom: [
              {
                id: v.id,
                rom: v.rom,
                tonase: thousandSeparator(v.tonase)
              }
            ]
          });
        }
      });
      state.pitToRomList.records = pitcontractorUnique;
      state.pitToRomList.loading = false;
    });
    builder.addCase(getPitToRomList.rejected, (state, action) => {
      state.pitToRomList.loading = false;
    });

    builder.addCase(getRomToPortList.pending, (state, action) => {
      state.romToPortList.loading = true;
    });
    builder.addCase(getRomToPortList.fulfilled, (state, action) => {
      const ptr = action.payload || [];
      let romToContractorUnique = [];
      ptr.forEach(function (v) {
        const findromToContractorUniqueIndex = romToContractorUnique.findIndex((vFind) => vFind.rom === v.rom && vFind.contractor === v.contractor)
        if (findromToContractorUniqueIndex > -1) {
          romToContractorUnique[findromToContractorUniqueIndex].port.push({
            id: v.id,
            port: v.port,
            tonase: v.tonase
          })
        } else {
          romToContractorUnique.push({
            rom: v.rom,
            contractor: v.contractor,
            port: [
              {
                id: v.id,
                port: v.port,
                tonase: v.tonase
              }
            ]
          });
        }
      });
      state.romToPortList.records = romToContractorUnique;
      state.romToPortList.loading = false;
    });
    builder.addCase(getRomToPortList.rejected, (state, action) => {
      state.romToPortList.loading = false;
    });

    builder.addCase(getCoalToMineList.pending, (state, action) => {
      state.coalToMineList.loading = true;
    });
    builder.addCase(getCoalToMineList.fulfilled, (state, action) => {
      state.coalToMineList.records = action.payload || [];
      state.coalToMineList.loading = false;
    });
    builder.addCase(getCoalToMineList.rejected, (state, action) => {
      state.coalToMineList.loading = false;
    });
    // actual coal fleet
    // fleet from pit
    builder.addCase(getFleetFromPitList.pending, (state, action) => {
      state.fleetFromPitList.loading = true;
    });
    builder.addCase(getFleetFromPitList.fulfilled, (state, action) => {
      state.fleetFromPitList.records = action.payload || null;
      state.fleetFromPitList.loading = false;
    });
    builder.addCase(getFleetFromPitList.rejected, (state, action) => {
      state.fleetFromPitList.loading = false;
    });
    // hauling fleet
    builder.addCase(getHaulingFleet.pending, (state, action) => {
      state.haulingFleet.loading = true;
    });
    builder.addCase(getHaulingFleet.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.haulingFleet.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.haulingFleet.loading = false;
    });
    builder.addCase(getHaulingFleet.rejected, (state, action) => {
      state.haulingFleet.loading = false;
    });
    // weather
    builder.addCase(getWeatherActual.pending, (state, action) => {
      state.weatherActual.loading = true;
    });
    builder.addCase(getWeatherActual.fulfilled, (state, action) => {
      state.weatherActual.records = action.payload || null;
      state.weatherActual.loading = false;
    });
    builder.addCase(getWeatherActual.rejected, (state, action) => {
      state.weatherActual.loading = false;
    });

    builder.addCase(getWeatherActualList.pending, (state, action) => {
      state.weatherActualList.loading = true;
    });
    builder.addCase(getWeatherActualList.fulfilled, (state, action) => {
      state.weatherActualList.records = action.payload || null;
      state.weatherActualList.loading = false;
    });
    builder.addCase(getWeatherActualList.rejected, (state, action) => {
      state.weatherActualList.loading = false;
    });
    builder.addCase(getWeatherActualDetailList.pending, (state, action) => {
      state.weatherActualDetail.loading = true;
    });
    builder.addCase(getWeatherActualDetailList.fulfilled, (state, action) => {
      const dataList = action.payload || [];
      let pitcontractorUnique = [];
      dataList.forEach(function (v) {
        const findPitContractorUniqueIndex = pitcontractorUnique.findIndex((vFind) => vFind.pit === v.pit && vFind.contractor === v.contractor)
        if (findPitContractorUniqueIndex > -1) {
          pitcontractorUnique[findPitContractorUniqueIndex].weather.push({
            id: v.id,
            rain_start: v.rain_start ? new Date(v.rain_start) : null,
            rain_end: v.rain_end ? new Date(v.rain_end) : null,
            slippery_start: v.slippery_start ? new Date(v.slippery_start) : null,
            slippery_end: v.slippery_end ? new Date(v.slippery_end) : null
          })
        } else {
          pitcontractorUnique.push({
            id: v.id || "",
            pit: v.pit || "",
            contractor: v.contractor || "",
            weather: [
              {
                id: v.id,
                rain_start: v.rain_start ? new Date(v.rain_start) : null,
                rain_end: v.rain_end ? new Date(v.rain_end) : null,
                slippery_start: v.slippery_start ? new Date(v.slippery_start) : null,
                slippery_end: v.slippery_end ? new Date(v.slippery_end) : null
              }
            ]
          });
        }
      });
      state.weatherActualDetail.records = pitcontractorUnique;
      state.weatherActualDetail.loading = false;
    });
    builder.addCase(getWeatherActualDetailList.rejected, (state, action) => {
      state.weatherActualDetail.loading = false;
    });
    // plan coal fleet
    builder.addCase(getPlanCoalFleet.pending, (state, action) => {
      state.planCoalFleet.loading = true;
    });
    builder.addCase(getPlanCoalFleet.fulfilled, (state, action) => {
      state.planCoalFleet.records = action.payload.length < 1 ? [] : action.payload[action.payload.length - 1];
      state.planCoalFleet.loading = false;
    });
    builder.addCase(getPlanCoalFleet.rejected, (state, action) => {
      state.planCoalFleet.loading = false;
    });
    builder.addCase(handleCreatePlanCoalFleet.pending, (state, action) => {
      state.planCoalFleet.loading = true;
    });
    builder.addCase(handleCreatePlanCoalFleet.fulfilled, (state, action) => {
      state.planCoalFleet.records = action?.payload?.data || [];
      state.planCoalFleet.loading = false;
    });
    builder.addCase(handleCreatePlanCoalFleet.rejected, (state, action) => {
      state.planCoalFleet.loading = false;
    });
    builder.addCase(handleUpdatePlanCoalFleet.pending, (state, action) => {
      state.planCoalFleet.loading = true;
    });
    builder.addCase(handleUpdatePlanCoalFleet.fulfilled, (state, action) => {
      state.planCoalFleet.records = action?.payload?.data || [];
      state.planCoalFleet.loading = false;
    });
    builder.addCase(handleUpdatePlanCoalFleet.rejected, (state, action) => {
      state.planCoalFleet.loading = false;
    });

    // stockpile
    builder.addCase(getStockpileList.pending, (state, action) => {
      state.stockpileList.loading = true;
    });
    builder.addCase(getStockpileList.fulfilled, (state, action) => {
      state.stockpileList.records = action.payload;
      state.stockpileList.loading = false;
    });
    builder.addCase(getStockpileList.rejected, (state, action) => {
      state.stockpileList.loading = false;
    });

    builder.addCase(getStockpileRomList.pending, (state, action) => {
      state.stockpileRomList.loading = true;
    });
    builder.addCase(getStockpileRomList.fulfilled, (state, action) => {
      state.stockpileRomList.records = action.payload || [];
      state.stockpileRomList.loading = false;
    });
    builder.addCase(getStockpileRomList.rejected, (state, action) => {
      state.stockpileRomList.loading = false;
    });

    builder.addCase(getStockpilePortList.pending, (state, action) => {
      state.stockpilePortList.loading = true;
    });
    builder.addCase(getStockpilePortList.fulfilled, (state, action) => {
      state.stockpilePortList.records = action.payload || [];
      state.stockpilePortList.loading = false;
    });
    builder.addCase(getStockpilePortList.rejected, (state, action) => {
      state.stockpilePortList.loading = false;
    });

    // file upload
    builder.addCase(handleFileUpload.pending, (state, action) => {
      state.fileUpload.loading = true;
    });
    builder.addCase(handleFileUpload.fulfilled, (state, action) => {
      state.fileUpload.records = action.payload || null;
      state.fileUpload.loading = false;
    });
    builder.addCase(handleFileUpload.rejected, (state, action) => {
      state.fileUpload.loading = false;
    });

    // WEATHER FORECAST
    builder.addCase(getWeatherForecast.pending, (state, action) => {
      state.weatherForecast.loading = true;
    });
    builder.addCase(getWeatherForecast.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.weatherForecast.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.weatherForecast.loading = false;
    });
    builder.addCase(getWeatherForecast.rejected, (state, action) => {
      state.weatherForecast.loading = false;
    });

    // barging
    builder.addCase(getBargingList.pending, (state, action) => {
      state.bargingList.loading = true;
    });
    builder.addCase(getBargingList.fulfilled, (state, action) => {
      state.bargingList.records = action.payload;
      state.bargingList.loading = false;
    });
    builder.addCase(getBargingList.rejected, (state, action) => {
      state.bargingList.loading = false;
    });
    // barging plan
    builder.addCase(getBargingPlan.pending, (state, action) => {
      state.bargingPlan.loading = true;
    });
    builder.addCase(getBargingPlan.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.bargingPlan.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.bargingPlan.loading = false;
    });
    builder.addCase(getBargingPlan.rejected, (state, action) => {
      state.bargingPlan.loading = false;
    });
    builder.addCase(getBargingPlanDetailList.pending, (state, action) => {
      state.bargingPlanDetail.loading = true;
    });
    builder.addCase(getBargingPlanDetailList.fulfilled, (state, action) => {
      state.bargingPlanDetail.records = action.payload;
      state.bargingPlanDetail.loading = false;
    });
    builder.addCase(getBargingPlanDetailList.rejected, (state, action) => {
      state.bargingPlanDetail.loading = false;
    });
    // barging actual
    builder.addCase(getBargingActual.pending, (state, action) => {
      state.bargingActual.loading = true;
    });
    builder.addCase(getBargingActual.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.bargingActual.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.bargingActual.loading = false;
    });
    builder.addCase(getBargingActual.rejected, (state, action) => {
      state.bargingActual.loading = false;
    });
    builder.addCase(getBargingActualDetailList.pending, (state, action) => {
      state.bargingActualDetail.loading = true;
    });
    builder.addCase(getBargingActualDetailList.fulfilled, (state, action) => {
      state.bargingActualDetail.records = action.payload;
      state.bargingActualDetail.loading = false;
    });
    builder.addCase(getBargingActualDetailList.rejected, (state, action) => {
      state.bargingActualDetail.loading = false;
    });

    // barging status and load progress
    builder.addCase(getBargingStatusList.pending, (state, action) => {
      state.bargingStatus.loading = true;
    });
    builder.addCase(getBargingStatusList.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.bargingStatus.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.bargingStatus.loading = false;
    });
    builder.addCase(getBargingStatusList.rejected, (state, action) => {
      state.bargingStatus.loading = false;
    });
    builder.addCase(getBargingProgressLoadList.pending, (state, action) => {
      state.bargingProgressLoad.loading = true;
    });
    builder.addCase(getBargingProgressLoadList.fulfilled, (state, action) => {
      state.bargingProgressLoad.records = action.payload;
      state.bargingProgressLoad.loading = false;
    });
    builder.addCase(getBargingProgressLoadList.rejected, (state, action) => {
      state.bargingProgressLoad.loading = false;
    });

    // barging forecast
    builder.addCase(getBargingForecastList.pending, (state, action) => {
      state.bargingForecast.loading = true;
    });
    builder.addCase(getBargingForecastList.fulfilled, (state, action) => {
      state.bargingForecast.records = action.payload;
      state.bargingForecast.loading = false;
    });
    builder.addCase(getBargingForecastList.rejected, (state, action) => {
      state.bargingForecast.loading = false;
    });

    // transshipment
    builder.addCase(getTransshipmentList.pending, (state, action) => {
      state.transshipmentList.loading = true;
    });
    builder.addCase(getTransshipmentList.fulfilled, (state, action) => {
      state.transshipmentList.records = action.payload;
      state.transshipmentList.loading = false;
    });
    builder.addCase(getTransshipmentList.rejected, (state, action) => {
      state.transshipmentList.loading = false;
    });
    // transshipment actual
    builder.addCase(getTransshipmentActualBeforeReportDate.pending, (state, action) => {
      state.transshipmentActualBefore.loading = true;
    });
    builder.addCase(getTransshipmentActualBeforeReportDate.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.transshipmentActualBefore.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.transshipmentActualBefore.loading = false;
    });
    builder.addCase(getTransshipmentActualBeforeReportDate.rejected, (state, action) => {
      state.transshipmentActualBefore.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailListBeforeReportDate.pending, (state, action) => {
      state.transshipmentActualDetailBefore.loading = true;
    });
    builder.addCase(getTransshipmentActualDetailListBeforeReportDate.fulfilled, (state, action) => {
      state.transshipmentActualDetailBefore.records = action.payload;
      state.transshipmentActualDetailBefore.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailListBeforeReportDate.rejected, (state, action) => {
      state.transshipmentActualDetailBefore.loading = false;
    });
    builder.addCase(getTransshipmentActual.pending, (state, action) => {
      state.transshipmentActual.loading = true;
    });
    builder.addCase(getTransshipmentActual.fulfilled, (state, action) => {
      const payload = action?.payload ?? []
      state.transshipmentActual.records = payload.length > 0 ? payload[payload.length - 1] : null;
      state.transshipmentActual.loading = false;
    });
    builder.addCase(getTransshipmentActual.rejected, (state, action) => {
      state.transshipmentActual.loading = false;
    });
    builder.addCase(getTransshipmentActualInRange.pending, (state, action) => {
      state.transshipmentActualInRange.loading = true;
    });
    builder.addCase(getTransshipmentActualInRange.fulfilled, (state, action) => {
      state.transshipmentActualInRange.records = action.payload;
      state.transshipmentActualInRange.loading = false;
    });
    builder.addCase(getTransshipmentActualInRange.rejected, (state, action) => {
      state.transshipmentActualInRange.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailList.pending, (state, action) => {
      state.transshipmentActualDetail.loading = true;
    });
    builder.addCase(getTransshipmentActualDetailList.fulfilled, (state, action) => {
      state.transshipmentActualDetail.records = action.payload;
      state.transshipmentActualDetail.loading = false;
    });
    builder.addCase(getTransshipmentActualDetailList.rejected, (state, action) => {
      state.transshipmentActualDetail.loading = false;
    });
    // transshipment today
    builder.addCase(getTransshipmentNextList.pending, (state, action) => {
      state.transshipmentNextList.loading = true;
    });
    builder.addCase(getTransshipmentNextList.fulfilled, (state, action) => {
      state.transshipmentNextList.records = action.payload;
      state.transshipmentNextList.loading = false;
    });
    builder.addCase(getTransshipmentNextList.rejected, (state, action) => {
      state.transshipmentNextList.loading = false;
    });
    // transshipment Floating Crane
    builder.addCase(getTransshipmentFloatingCraneList.pending, (state, action) => {
      state.transshipmentFloatingCraneList.loading = true;
    });
    builder.addCase(getTransshipmentFloatingCraneList.fulfilled, (state, action) => {
      state.transshipmentFloatingCraneList.records = action.payload;
      state.transshipmentFloatingCraneList.loading = false;
    });
    builder.addCase(getTransshipmentFloatingCraneList.rejected, (state, action) => {
      state.transshipmentFloatingCraneList.loading = false;
    });
    // transshipment forecast
    builder.addCase(getTransshipmentForecastList.pending, (state, action) => {
      state.transshipmentForecast.loading = true;
    });
    builder.addCase(getTransshipmentForecastList.fulfilled, (state, action) => {
      state.transshipmentForecast.records = action.payload;
      state.transshipmentForecast.loading = false;
    });
    builder.addCase(getTransshipmentForecastList.rejected, (state, action) => {
      state.transshipmentForecast.loading = false;
    });
  },
});

export const {
  onAddStockpileRomList,
  onDelStockpileRomList,
  onDelStockpilePortList,
  onAddStockpilePortList,
  onChangeStockpileRomList,
  onChangeStockpilePortList,
} = executiveReportSlice.actions;

export const productionSelector = (state) => state.executiveReportReducer.productionList;
export const pitToPortSelector = (state) => state.executiveReportReducer.pitToPortList;
export const mdPitSelector = (state) => state.executiveReportReducer.mdPit;
export const mdContractorSelector = (state) => state.executiveReportReducer.mdContractor;
export const mdPortSelector = (state) => state.executiveReportReducer.mdPort;
export const mdRomSelector = (state) => state.executiveReportReducer.mdRom;
export const mdFloatingCraneSelector = (state) => state.executiveReportReducer.mdFloatingCrane;
export const pitToRomSelector = (state) => state.executiveReportReducer.pitToRomList;
export const romToPortSelector = (state) => state.executiveReportReducer.romToPortList;
export const coalToMineSelector = (state) => state.executiveReportReducer.coalToMineList;
// actual coal fleet
// fleet from pit
export const fleetFromPitListSelector = (state) => state.executiveReportReducer.fleetFromPitList;
// hauling fleet
export const haulingFleetSelector = (state) => state.executiveReportReducer.haulingFleet;
// plan coal fleet
export const planCoalFleetSelector = (state) => state.executiveReportReducer.planCoalFleet;
// production weather
export const weatherActualSelector = (state) => state.executiveReportReducer.weatherActual;
export const weatherActualListSelector = (state) => state.executiveReportReducer.weatherActualList;
export const weatherActualDetailSelector = (state) => state.executiveReportReducer.weatherActualDetail;
export const weatherForecastSelector = (state) => state.executiveReportReducer.weatherForecast;
// file upload
export const fileUploadSelector = (state) => state.executiveReportReducer.fileUpload;

// stockpile
export const stockpileSelector = (state) => state.executiveReportReducer.stockpileList;
export const stockpileRomSelector = (state) => state.executiveReportReducer.stockpileRomList;
export const stockpilePortSelector = (state) => state.executiveReportReducer.stockpilePortList;

// barging
export const bargingSelector = (state) => state.executiveReportReducer.bargingList;
export const BargingPlanSelector = (state) => state.executiveReportReducer.bargingPlan;
export const BargingPlanDetailSelector = (state) => state.executiveReportReducer.bargingPlanDetail;
export const BargingActualSelector = (state) => state.executiveReportReducer.bargingActual;
export const BargingActualDetailSelector = (state) => state.executiveReportReducer.bargingActualDetail;
export const bargingStatusSelector = (state) => state.executiveReportReducer.bargingStatus;
export const bargingProgressLoadSelector = (state) => state.executiveReportReducer.bargingProgressLoad;
export const BargingForecastSelector = (state) => state.executiveReportReducer.bargingForecast;

// transshipment
export const transshipmentSelector = (state) => state.executiveReportReducer.transshipmentList;
// transshipment actual
export const transshipmentActualBeforeSelector = (state) => state.executiveReportReducer.transshipmentActualBefore;
export const transshipmentActualSelector = (state) => state.executiveReportReducer.transshipmentActual;
export const transshipmentActualInRangeSelector = (state) => state.executiveReportReducer.transshipmentActualInRange;
export const transshipmentActualDetailBeforeSelector = (state) => state.executiveReportReducer.transshipmentActualDetailBefore;
export const transshipmentActualDetailSelector = (state) => state.executiveReportReducer.transshipmentActualDetail;
// transshipment today
export const transshipmentNextSelector = (state) => state.executiveReportReducer.transshipmentNextList;
// transshipment Floating Crane
export const transshipmentFloatingCraneSelector = (state) => state.executiveReportReducer.transshipmentFloatingCraneList;
// transshipment Forecast
export const transshipmentForecastSelector = (state) => state.executiveReportReducer.transshipmentForecast;

export default executiveReportSlice.reducer;
