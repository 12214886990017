import { combineReducers } from "redux";

import appReducer from "containers/App/reducer";
import coalGettingReducer from "containers/CoalGetting/reducer";
import distanceCoalGettingReducer from "containers/Configuration/Distance/CoalGetting/reducer.js";
import distanceWasteRemovalReducer from "containers/Configuration/Distance/WasteRemoval/reducer.js";
import payloadCoalGettingReducer from "containers/Configuration/Payload/CoalGetting/reducer";
import payloadWasteRemovalReducer from "containers/Configuration/Payload/WasteRemoval/reducer";
import slipperyCoalGettingReducer from "containers/Configuration/Slipperly/CoalGetting/reducer.js";
import slipperyWasteRemovalReducer from "containers/Configuration/Slipperly/WasteRemoval/reducer.js";
import confEwhReducer from "containers/Configuration/Ewh/reducer";
import approvalConfigReducer from "containers/Configuration/ApprovalConfig/reducer";
import modulReducer from "containers/Configuration/Modul/reducer";
import roleReducer from "containers/Configuration/Role/reducer";
import userConfigReducer from "containers/Configuration/UserConfig/reducer";
import longTermPlanningReducer from "containers/LongPlanning/reducer";
import ConfCrusherReducer from "containers/Configuration/ConfCrusher/reducer";
import ConfJettyReducer from "containers/Configuration/JetyLoading/reducer";
import masterDataReducer from "containers/MasterData/reducer";
import midTermPlanningReducer from "containers/MidPlanning/reducer";
import romInventoryReducer from "containers/RomInventory/reducer";
import shortTermPlanningReducer from "containers/ShortPlanning/reducer";
import shortTermPlanningDailyReducer from "containers/ShortPlanningDaily/reducer";
import pitProgressReducer from "containers/Survey/reducer.js";
import dashboardReducer from "containers/WasteRemoval/reducer";
import baDistanceHaulingReducer from "containers/BADistanceHauling/reducer";
import fleetPlanHaulingReducer from "containers/FleetPlanHauling/reducer";
import fleetActualHaulingReducer from "containers/FleetActualHauling/reducer";
import wasteHaulDistanceReducer from "containers/WasteHaulDistance/reducer";
import coalHaulDistanceReducer from "containers/CoalHaulDistance/reducer";
import fleetPlanRomReducer from "containers/ROMFeetPlan/reducer";
import fleetActualRomReducer from "containers/ROMFeetActual/reducer";
import requestSubLocationReducer from "containers/RequestMasterData/SubLocation/reducer";
import requestSubLocationContractorReducer from "containers/RequestMasterDataContractor/SubLocation/reducer";
import requestEquipmentTypeReducer from "containers/RequestMasterData/EquipmentType/reducer";
import requestEquipmentTypeContractorReducer from "containers/RequestMasterDataContractor/EquipmentType/reducer";
import requestEquipmentDetailReducer from "containers/RequestMasterData/EquipmentDetail/reducer";
import requestEquipmentDetailContractorReducer from "containers/RequestMasterDataContractor/EquipmentDetail/reducer";
import requestMaterialReducer from "containers/RequestMasterData/Material/reducer";
import requestMaterialContractorReducer from "containers/RequestMasterDataContractor/Material/reducer";
import requestEquipmentROMReducer from "containers/RequestMasterData/EquipmentROM/reducer";
import requestEquipmentROMContractorReducer from "containers/RequestMasterDataContractor/EquipmentROM/reducer";
import requestEquipmentHaulReducer from "containers/RequestMasterData/EquipmentHaul/reducer";
import requestEquipmentHaulContractorReducer from "containers/RequestMasterDataContractor/EquipmentHaul/reducer";
import portCrusherPlan from "containers/Port/CrushingPlantPlan/reducer";
import portJettyLoadingPlans from "containers/Port/JettyLoadingPlan/reducer";
import actualAdminCrusher from "containers/Port/CrushingActualAdmin/reducer";
import actualAdminBlc from "containers/Port/BlcActualAdmin/reducer";
import actualCrusher from "containers/Port/CrushingActual/reducer";
import actualBlc from "containers/Port/BlcActual/reducer";
import notifications from "containers/Notification/reducer";
import payload from "containers/Configuration/Payload/reducer";
import actualAdminJetty from "containers/Port/JettyActualAdmin/reducer";
import actualJetty from "containers/Port/JettyActual/reducer";
import dashboardProductionReducer from 'containers/DashboardProduction/reducer';
import romDashboardProductionReducer from 'containers/ROMDashboardProduction/reducer';
import romPlanReducer from "containers/Port/RomPlan/reducer";
import actualAdminRom from "containers/Port/RomActualAdmin/reducer";
import confRom from "containers/Configuration/ConfRom/reducer";
import actualRom from "containers/Port/RomActual/reducer";
import romExcavatorConfiguration from "containers/Port/RomExcavator/Configuration/reducer";
import romExcavatorActual from "containers/Port/RomExcavator/Actual/reducer";

import effectiveWorkHourReducer from "containers/EffectiveWorkHour/WasteRemoval/reducer";
import effectiveWorkHourCoalReducer from "containers/EffectiveWorkHour/CoalGetting/reducer";

import portReportReducer from "containers/Port/PortReport/reducer";

import P2HReducer from 'containers/MasterData/P2H/reducer';
import ROMP2HReducer from 'containers/ROMP2H/reducer';

import dashboardPerformanceReducer from 'containers/DashboardPerformance/reducer';

import executiveReportReducer from "containers/QuickExecutiveReport/reducer";

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    global: appReducer,
    dashboard: dashboardReducer,
    payloadWasteRemoval: payloadWasteRemovalReducer,
    payloadCoalGetting: payloadCoalGettingReducer,
    slipperyWasteRemoval: slipperyWasteRemovalReducer,
    slipperyCoalGetting: slipperyCoalGettingReducer,
    distanceWasteRemoval: distanceWasteRemovalReducer,
    distanceCoalGetting: distanceCoalGettingReducer,
    wasteRemoval: dashboardReducer,
    masterData: masterDataReducer,
    coalGetting: coalGettingReducer,
    romInventory: romInventoryReducer,
    pitProgress: pitProgressReducer,
    longTermPlanning: longTermPlanningReducer,
    shortTermPlanning: shortTermPlanningReducer,
    shortTermPlanningDaily: shortTermPlanningDailyReducer,
    midTermPlanning: midTermPlanningReducer,
    ConfCrusher: ConfCrusherReducer,
    ConfJetty: ConfJettyReducer,
    ConfEwh: confEwhReducer,
    approvalConfig: approvalConfigReducer,
    modul: modulReducer,
    role: roleReducer,
    userConfig: userConfigReducer,
    baDistanceHauling: baDistanceHaulingReducer,
    fleetPlanHauling: fleetPlanHaulingReducer,
    fleetActualHauling: fleetActualHaulingReducer,
    wasteHaulDistance: wasteHaulDistanceReducer,
    coalHaulDistance: coalHaulDistanceReducer,
    fleetPlanRom: fleetPlanRomReducer,
    fleetActualRom: fleetActualRomReducer,
    requestSubLocation: requestSubLocationReducer,
    requestSubLocationContractor: requestSubLocationContractorReducer,
    requestEquipmentType: requestEquipmentTypeReducer,
    requestEquipmentTypeContractor: requestEquipmentTypeContractorReducer,
    requestEquipmentDetail: requestEquipmentDetailReducer,
    requestEquipmentDetailContractor: requestEquipmentDetailContractorReducer,
    requestMaterial: requestMaterialReducer,
    requestMaterialContractor: requestMaterialContractorReducer,
    requestEquipmentROM: requestEquipmentROMReducer,
    requestEquipmentROMContractor: requestEquipmentROMContractorReducer,
    requestEquipmentHaul: requestEquipmentHaulReducer,
    requestEquipmentHaulContractor: requestEquipmentHaulContractorReducer,
    portCrusherPlan: portCrusherPlan,
    portJettyLoadingPlans: portJettyLoadingPlans,
    actualAdminCrusher: actualAdminCrusher,
    actualAdminBlc: actualAdminBlc,
    actualCrusher: actualCrusher,
    actualBlc: actualBlc,
    notifications: notifications,
    payload: payload,
    effectiveWorkHour: effectiveWorkHourReducer,
    effectiveWorkHourCoal: effectiveWorkHourCoalReducer,
    portReport: portReportReducer,
    P2H: P2HReducer,
    actualAdminJetty: actualAdminJetty,
    actualJetty: actualJetty,
    dashboardProduction: dashboardProductionReducer,
    romDashboardProduction: romDashboardProductionReducer,
    romPlan: romPlanReducer,
    actualAdminRom: actualAdminRom,
    confRom: confRom,
    actualRom: actualRom,
    ROMP2H: ROMP2HReducer,
    dashboardPerformance: dashboardPerformanceReducer,
    romExcavatorConfiguration: romExcavatorConfiguration,
    romExcavatorActual: romExcavatorActual,
    executiveReportReducer: executiveReportReducer,
    ...injectedReducers,
  });
}
