import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { apiUrls, get, post } from 'utils/request';
import { sum, cloneDeep, sumBy, round } from 'lodash';
import { } from '../MidPlanning/reducer';
import { DATASOURCE_VALUE } from 'utils/helper';
import { monthList, shortMonthListEN as shortMonthList } from 'utils/constants';
import moment from 'moment';

export const initialState = {
  shortTermPlanningDaily: {
    loading: false,
    records: [],
    recordsTotal: '',
    error: '',
  },
  shortTermPlanningDailyDetail: {
    loading: false,
    records: [],
    error: '',
    version: 1,
    defaultTable: [
      { code: "waste", parameter: "Waste Inpit", total: 0 },
      { code: "waste", parameter: "Waste Outpit & Project", total: 0 },
      { code: "totalwaste", parameter: "Total Waste", total: 0, isHeader: true },
      { code: "coal", parameter: "Coal Getting", total: 0 },
      { code: "srInpit", parameter: "SR Inpit", total: 0, isHeader: true },
      { code: "srAll", parameter: "SR All", total: 0, isHeader: true },
    ],
    workingHour: {
      availableDays: [],
      workingHours: [],
      otherDelayHours: [],
    },
    dailyData: [],
    year: '',
    data: {

    },
    dailyDateYear: [],
    dailyColumnSetup: [],
  },
  shortTermPlanningDailyDetailColumn: {
    defaultColumn: [],
    dailyColumn: [],
  },
  versionList: {
    loading: false,
    versionList: [1],
    error: '',
  },
  currentObject: {},
  contractorByArea: {
    records: [],
    loading: false,
    error: ""
  },
};

export const getShortTermPlanningDaily = createAsyncThunk(
  'shortTermPlanningDaily/get',
  async (params) => {
    const response = await post(`${apiUrls.shortTermPlanningsDaily}/filter`, {
      columns: [
        {
          data: 'reportId',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.reportId || '',
          searchable: true,
        },
        {
          data: 'createdAt',
          orderable: true,
          search: {
            regex: true,
            value: '',
          },
          searchValue: params.date || '',
          searchable: true,
        },
        {
          data: 'year',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.year || '',
          searchable: true,
        },
        {
          data: 'version',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.version || '',
          searchable: true,
        },
        {
          data: 'contractor',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.contractor || '',
          searchable: true,
        },
        {
          data: 'dataStatus',
          orderable: true,
          search: {
            regex: params.status === DATASOURCE_VALUE.waitingApproval || false,
            value: '',
          },
          searchValue: params.status || '',
          searchable: true,
        },
        {
          data: 'contractor',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.contractor || '',
          searchable: true,
        },
        {
          data: 'locationCode',
          orderable: true,
          search: {
            regex: false,
            value: '',
          },
          searchValue: params.locationCode || '',
          searchable: true,
        },
      ],
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params.dataLength ? params.dataLength : -1,
      order: [{
        column: 1,
        dir: "desc",
      }],
      search: {
        regex: true,
        value: params.query || '',
      },
    });
    return response?.data;
  }
);

export const getShortTermPlanningDailyDetail = createAsyncThunk(
  'shortTermPlanningDaily/detail',
  async (params) => {
    const response = await get(`${apiUrls.shortTermPlanningsDaily}/${params}`);
    return response?.data;
  }
);

export const getShortTermPlanningDailyById = createAsyncThunk(
  'shortTermPlanningDaily/getById',
  async (params) => {
    const response = await post(`${apiUrls.shortTermPlanningsDaily}/list-id`,
      params
    );
    return response?.data;
  }
);

export const getVersionListNew = createAsyncThunk(
  'shortTermPlanningDaily/versionlist',
  async (reportId) => {
    const response = await get(
      `${apiUrls.shortTermPlanningsDaily}/${reportId}/versions`
    );
    return response?.data;
  }
);

export const getByVersionNew = createAsyncThunk(
  'shortTermPlanningDaily/getByVersion',
  async (params) => {
    const response = await get(
      `${apiUrls.shortTermPlanningsDaily}/${params.reportId}/versions/${params.version}`
    );
    return response?.data;
  }
);

export const getLatestAvailableDay = createAsyncThunk(
  'shortTermPlanningDaily/getLatestAvailableDay',
  async (params) => {
    const { contractorCode, isAll } = params;
    const urlSearchAll = isAll ? '/all' : `?contractorCode=${contractorCode}`;
    const response = await get(
      `${apiUrls.slipperyWasteRemoval}/latest-available-day${urlSearchAll}`
    );
    return response?.data;
  }
);

export const fetchContractorByArea = createAsyncThunk(
  `master-data/area-by-name`,
  async (params) => {
    const { area } = params;
    const response = await get(`${apiUrls.masterData}/areas/names/${area}/contractors`);
    return response?.data;
  }
);

export const intervensiShortTermPlanningDaily = createAsyncThunk(
  'shortTermPlanningDaily/intervensi',
  async (params) => {
    let response
    try {
      response = await post(`${apiUrls.intervensiMinePlan}/user-role?moduleName=ShortTermPlan`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const getLatestDetailData = createAsyncThunk(
  'shortTermPlanningDaily/latest-detail',
  async (params) => {
    const { contractorCode, pitCode } = params;
    const response = await get(`${apiUrls.shortTermPlanningsDaily}/contractor-code/${contractorCode}/pit-code/${pitCode}`);
    return response?.data;
  }
);


const SHORT_TERM_PLANNING_DAILY = 'SHORT_TERM_PLANNING_DAILY';

const shortTermPlanningDaily = createSlice({
  name: SHORT_TERM_PLANNING_DAILY,
  initialState,
  reducers: {
    clearShortTermPlanningDailyDetail: (state, action) => {
      state.shortTermPlanningDailyDetail = {
        loading: false,
        records: [],
        error: '',
        defaultTable: [
          { code: "waste", parameter: "Waste Inpit", total: 0 },
          { code: "waste", parameter: "Waste Outpit & Project", total: 0 },
          { code: "totalwaste", parameter: "Total Waste", total: 0, isHeader: true },
          { code: "coal", parameter: "Coal Getting", total: 0 },
          { code: "srInpit", parameter: "SR Inpit", total: 0, isHeader: true },
          { code: "srAll", parameter: "SR All", total: 0, isHeader: true },
        ],
        workingHour: {
          availableDays: [],
          workingHours: [],
          otherDelayHours: [],
        },
        dailyData: [],
        dailyDateYear: [],
        dailyColumnSetup: [],
      };
    },
    createColumnShortPlanningDailyDetail: (state, action) => {
      const payload = action.payload;
      state.shortTermPlanningDailyDetailColumn[payload.tableName] = payload.data;
    },
    onChangeYearNew: (state, action) => {
      const payload = action.payload;
      state.shortTermPlanningDailyDetail.year = payload;
      const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let columnDate = []

      for (let i = 1; i <= 12; i++) {
        let monthIndex = i - 1
        let monthObject = {
          value: i,
          label: monthList[monthIndex]
        }
        if (state.shortTermPlanningDailyDetail.year === undefined) {
          state.shortTermPlanningDailyDetail.year = new Date().getFullYear();
        }
        const countDays = i
          ? moment()
            .set("years", state.shortTermPlanningDailyDetail.year)
            .set("months", monthIndex)
            .daysInMonth()
          : 0;
        if (countDays !== 0) {

          Array(countDays)
            .fill("")
            .map((e, i) => {
              e = i + 1;
              columnDate.push(`${monthObject.label}${e}`);
            });

          columnDate.push(`${monthObject.label}`);

        }
      }

      state.shortTermPlanningDailyDetail.dailyDateYear = columnDate

    },

    onChangeTableMonthlyNew: (state, action) => {
      const payload = action.payload;

      let val = parseInt(payload.value, 10);
      if (payload.value?.includes('.'))
        val = parseFloat(payload.value);

      state.shortTermPlanningDailyDetail.defaultTable[payload.tableIndexRow][payload.tableRowName] = val || 0;

      if (payload.tableType === "waste" || payload.tableType === "coal") {
        const cloneRow = cloneDeep(state.shortTermPlanningDailyDetail.defaultTable[payload.tableIndexRow]);
        delete cloneRow['code'];
        delete cloneRow['parameter'];
        delete cloneRow['total'];
        const dataRow = Object.values(cloneRow).map(dt => parseFloat(dt.toString().replace(",", ".")));
        const rowFilterNumber = dataRow.filter(Number);
        let totalRow = sum(rowFilterNumber);

        // state.shortTermPlanningDailyDetail.defaultTable[payload.tableIndexRow]["total"] = totalRow || 0;

        // const dtWaste = state.shortTermPlanningDailyDetail.defaultTable.filter(el => el.parameter === "waste" && !el.isHeader);
        // let countHeader = sumBy(dtWaste, function (o) { return parseFloat(o[payload.tableRowName]?.toString().replace(",", ".") || 0); })
        // state.shortTermPlanningDailyDetail.defaultTable[2][payload.tableRowName] = countHeader || 0;
      }

    },

    counterDataTableNew: (state, action) => {
      const payload = action.payload;
      if (payload.tableType === "waste") {
        const dtWaste = state.shortTermPlanningDailyDetail.defaultTable.filter(el => el.code === "waste" && !el.isHeader);
        let countHeader = sumBy(dtWaste, function (o) { return parseFloat(o[payload.tableRowName]?.toString().replace(",", ".") || 0); })
        if (countHeader > 0 && countHeader % 1 !== 0) {
          countHeader = parseFloat(countHeader).toFixed(3);
        }
        state.shortTermPlanningDailyDetail.defaultTable[2][payload.tableRowName] = countHeader || 0;
        state.shortTermPlanningDailyDetail.defaultTable[4][payload.tableRowName] = 0;
        state.shortTermPlanningDailyDetail.defaultTable[5][payload.tableRowName] = 0;

        const cloneRowWaste = cloneDeep(state.shortTermPlanningDailyDetail.defaultTable[0]);
        delete cloneRowWaste['code'];
        delete cloneRowWaste['parameter'];
        delete cloneRowWaste['total'];
        const dataRowHeadWaste = Object.values(cloneRowWaste).map(dt => parseFloat(dt.toString().replace(",", ".")));
        const countWasteHeader = dataRowHeadWaste.filter(Number);
        let totalRowWaste = sum(countWasteHeader);
        state.shortTermPlanningDailyDetail.defaultTable[0]["total"] = totalRowWaste || 0;
      }

      if (payload.tableType === "waste" || payload.tableType === "coal") {
        state.shortTermPlanningDailyDetail.defaultTable[4][payload.tableRowName] = 0;

        if (state.shortTermPlanningDailyDetail.defaultTable[0][payload.tableRowName] > 0 && state.shortTermPlanningDailyDetail.defaultTable[3][payload.tableRowName] > 0) {
          state.shortTermPlanningDailyDetail.defaultTable[4][payload.tableRowName] = (state.shortTermPlanningDailyDetail.defaultTable[0][payload.tableRowName] / state.shortTermPlanningDailyDetail.defaultTable[3][payload.tableRowName]);
          state.shortTermPlanningDailyDetail.defaultTable[4][payload.tableRowName] = state.shortTermPlanningDailyDetail.defaultTable[4][payload.tableRowName].toFixed(3)
        }

        state.shortTermPlanningDailyDetail.defaultTable[5][payload.tableRowName] = 0;
        if (state.shortTermPlanningDailyDetail.defaultTable[2][payload.tableRowName] > 0 && state.shortTermPlanningDailyDetail.defaultTable[3][payload.tableRowName] > 0) {
          state.shortTermPlanningDailyDetail.defaultTable[5][payload.tableRowName] = (state.shortTermPlanningDailyDetail.defaultTable[2][payload.tableRowName] / state.shortTermPlanningDailyDetail.defaultTable[3][payload.tableRowName]);
          state.shortTermPlanningDailyDetail.defaultTable[5][payload.tableRowName] = state.shortTermPlanningDailyDetail.defaultTable[5][payload.tableRowName].toFixed(3)
        }
      }
    },

    addUploadDataTableMonthlyNew: (state, action) => {
      const defData = [
        { code: "waste", parameter: "Waste Inpit", total: 0 },
        { code: "waste", parameter: "Waste Outpit & Project", total: 0 },
        { code: "totalwaste", parameter: "Total Waste", total: 0, isHeader: true },
        { code: "coal", parameter: "Coal Getting", total: 0 },
        { code: "srInpit", parameter: "SR Inpit", total: 0, isHeader: true },
        { code: "srAll", parameter: "SR All", total: 0, isHeader: true },
      ];

      const itemWasteInpit = action.payload?.itemWasteInpit
      const itemWasteOutpit = action.payload?.itemWasteOutpit
      const itemCoalGetting = action.payload?.itemCoalGetting

      let finalData = [];
      finalData = defData.map((dt) => {
        let tempData = {
          code: dt.code,
          parameter: dt.parameter,
          total: dt.total,
          isHeader: (dt.code === "totalwaste" || dt.code === "srInpit" || dt.code === "srAll") ? true : false,
        };
        if (dt.parameter == "Waste Inpit") {
          for (const month of monthList) {
            if (itemWasteInpit[month.label.toLocaleLowerCase()]) {
              for (const data of itemWasteInpit[month.label.toLowerCase()]) {
                tempData[`${month.label.substring(0, 3) + data.day}`] = data.value || 0
              }
            }
          }
        }
        if (dt.parameter == "Waste Outpit & Project") {
          for (const month of monthList) {
            if (itemWasteOutpit[month.label.toLocaleLowerCase()]) {
              for (const data of itemWasteOutpit[month.label.toLowerCase()]) {
                tempData[`${month.label.substring(0, 3) + data.day}`] = data.value || 0
              }
            }
          }
        }
        if (dt.parameter == "Coal Getting") {
          for (const month of monthList) {
            if (itemCoalGetting[month.label.toLocaleLowerCase()]) {
              for (const data of itemCoalGetting[month.label.toLowerCase()]) {
                tempData[`${month.label.substring(0, 3) + data.day}`] = data.value || 0
              }
            }
          }
        }
        for (const mon of shortMonthList) {
          tempData[mon] = 0
        }
        return tempData;
      });

      let totalWasteCal = {}
      let totalSRInpit = {}
      let totalSRAll = {}
      for (const month of monthList) {
        if (itemWasteInpit[month.label.toLocaleLowerCase()]) {
          for (const data of itemWasteInpit[month.label.toLowerCase()]) {
            const findWasteInpit = itemWasteInpit[month.label.toLowerCase()].find(item => item.day === data.day);
            const findWasteOutpit = itemWasteOutpit[month.label.toLowerCase()].find(item => item.day === data.day);
            const findCoalGetting = itemCoalGetting[month.label.toLowerCase()].find(item => item.day === data.day);
            const totalWaste = findWasteInpit.value + findWasteOutpit.value
            totalWasteCal[`${month.label.substring(0, 3) + data.day}`] = totalWaste || 0
            if (findWasteInpit.value > 0 && findCoalGetting.value > 0) {
              const srInpit = findWasteInpit.value / findCoalGetting.value
              totalSRInpit[`${month.label.substring(0, 3) + data.day}`] = srInpit || 0
            }
            if (totalWaste > 0 && findCoalGetting.value > 0) {
              const srAll = totalWaste / findCoalGetting.value
              totalSRAll[`${month.label.substring(0, 3) + data.day}`] = srAll || 0
            }
          }
        }
      }

      finalData[2] = {
        ...finalData[2],
        ...totalWasteCal
      }
      finalData[4] = {
        ...finalData[4],
        ...totalSRInpit
      }
      finalData[5] = {
        ...finalData[5],
        ...totalSRAll
      }

      state.shortTermPlanningDailyDetail.defaultTable = finalData;
      state.shortTermPlanningDailyDetail.year = action.payload.year;
    },

    createDailyDataNewCustomNew: (state, action) => {
      const payload = action.payload;
      const { data, isSearchAll, workingHour, workingHourAll } = payload;
      const sumShift = (payload?.day * 2) || 0;

      let result = [];
      const tempData = data.map(dt => {
        return {
          code: dt.code,
          header: dt.header,
          isHeader: dt.isHeader,
          unit: dt.unit,
          value: dt[shortMonthList[payload.month?.value - 1]],
        }
      });

      const getWorkingHours = (data) => {
        const workingHours = data.map((item) => {
          return item.workingHours;
        });
        return workingHours.flat();
      };


      const singleWorkingHour = workingHour?.workingHours || [];
      const multipleWorkingHour = getWorkingHours(workingHourAll) || [];
      const workingHours = isSearchAll ? multipleWorkingHour : singleWorkingHour;

      const listHoliday = [];
      const workingHoursByDate = workingHours.map(wh => {
        const dt = { ...wh };
        if (wh.startDate) {
          let val = wh.holidayDuration;
          const durDays = moment(wh.endDate).diff(moment(wh.startDate), 'days') + 1;
          let day = moment(wh.startDate);
          for (let i = 0; i < durDays; i++) {
            const e = day.format("D/M/yyyy");
            const holidayValue = (val - 2 >= 0) ? 2 : val;
            dt[e] = holidayValue;
            listHoliday.push({
              date: e,
              value: holidayValue < 0 ? 0 : holidayValue,
            });
            val -= 2;
            day = day.add(1, 'days');
          }
        }
        return {
          ...dt,
          month: wh.startDate ? parseInt(moment(wh.startDate).format('M')) : 0,
        };
      });

      const totalShift = sumShift - (workingHoursByDate.length > 0 ? sumBy(workingHoursByDate.filter(el => el.month === payload.month.value), 'holidayDuration') : 0)

      result = tempData.map(dt => {
        const nd = { ...dt, month: dt.value };
        for (let i = 1; i <= payload?.day; i++) {
          let countShift = 2;
          const { month, year } = payload;
          const holiday = listHoliday.find(item => item.date === `${i}/${month.value}/${year}`);
          if (holiday) {
            countShift -= holiday.value;
          }
          const value = dt.value;
          const getMonthYear = (payload) => `${payload.month.value}/${payload.year}`;
          nd[`${i}/${getMonthYear(payload)}`] = (dt.code === "haul" || dt.code === "quality") ?
            value : Number(((value / totalShift) * countShift).toFixed(3));
        }
        return nd;
      });

      state.shortTermPlanningDailyDetail.dailyData = result
    },

    clearDailyTableNew: (state, action) => {
      state.shortTermPlanningDailyDetail.dailyData = [];
      state.shortTermPlanningDailyDetail.dailyColumnSetup = [];
      state.shortTermPlanningDailyDetailColumn.dailyColumn = []
    },

    resetShortTermPlanningDailyDetail: (state, action) => {
      state.shortTermPlanningDailyDetail.defaultTable = [
        { code: "waste", parameter: "Waste Inpit", total: 0 },
        { code: "waste", parameter: "Waste Outpit & Project", "total": 0 },
        { code: "totalwaste", parameter: "Total Waste", total: 0, isHeader: true },
        { code: "coal", parameter: "Coal Getting", total: 0 },
        { code: "srInpit", parameter: "SR Inpit", total: 0, isHeader: true },
        { code: "srAll", parameter: "SR All", total: 0, isHeader: true },
      ]
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getShortTermPlanningDaily.pending, (state, action) => {
      state.shortTermPlanningDaily.loading = true;
    });
    builder.addCase(getShortTermPlanningDaily.fulfilled, (state, action) => {
      state.shortTermPlanningDaily.loading = false;
      state.shortTermPlanningDaily.records = action.payload.list;
      state.shortTermPlanningDaily.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getShortTermPlanningDaily.rejected, (state, action) => {
      state.shortTermPlanningDaily.loading = false;
      state.shortTermPlanningDaily.error = 'Invalid get data';
    });

    builder.addCase(getShortTermPlanningDailyDetail.pending, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = true;
    });
    builder.addCase(getShortTermPlanningDailyDetail.fulfilled, (state, action) => {
      let dtTable = (action.payload?.data.dailyPlan.dataWasteRemoval || []).concat(
        action.payload?.data.dailyPlan.dataTotalWaste || []
      ).concat(
        action.payload?.data.dailyPlan.dataCoalGetting || []
      ).concat(
        action.payload?.data.dailyPlan.dataSRInpit || []
      ).concat(
        action.payload?.data.dailyPlan.dataSRAll || []
      )

      let finalData = [];
      finalData = dtTable.map((dt) => {
        let tempData = {
          code: dt.code,
          parameter: dt.parameter,
          total: dt.total,
          isHeader: (dt.code === "totalwaste" || dt.code === "srInpit" || dt.code === "srAll") ? true : false,
        };
        for (const month of monthList) {
          if (dt[month.label]) {
            for (const data of dt[month.label]) {
              tempData[`${month.label.substring(0, 3) + data.day}`] = data.value || 0
            }
          }
        }
        for (const mon of shortMonthList) {
          tempData[mon] = 0
        }
        return tempData;
      });

      let data = { ...action.payload, defaultTable: finalData }
      state.shortTermPlanningDailyDetail.loading = false;

      state.shortTermPlanningDailyDetail = data;
    });
    builder.addCase(getShortTermPlanningDailyDetail.rejected, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = false;
      state.shortTermPlanningDailyDetail.error = 'Invalid get data';
    });

    builder.addCase(getVersionListNew.pending, (state, action) => {
      state.versionList.loading = true;
    });
    builder.addCase(getVersionListNew.fulfilled, (state, action) => {
      state.versionList.versionList = action.payload;
    });
    builder.addCase(getVersionListNew.rejected, (state, action) => {
      state.versionList.loading = false;
      state.versionList.error = 'Invalid get data';
    });

    builder.addCase(getByVersionNew.pending, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = true;
    });
    builder.addCase(getByVersionNew.fulfilled, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = false;
      state.shortTermPlanningDailyDetail.versionList = action.payload;
    });
    builder.addCase(getByVersionNew.rejected, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = false;
      state.shortTermPlanningDailyDetail.error = 'Invalid get data';
    });

    builder.addCase(getLatestAvailableDay.pending, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = true;
    });
    builder.addCase(getLatestAvailableDay.fulfilled, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = false;
      state.shortTermPlanningDailyDetail.workingHour = action.payload;
    });
    builder.addCase(getLatestAvailableDay.rejected, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = false;
      state.shortTermPlanningDailyDetail.error = 'Invalid get data';
    });

    builder.addCase(fetchContractorByArea.pending, (state, action) => {
      state.contractorByArea.loading = true;
    });
    builder.addCase(fetchContractorByArea.fulfilled, (state, action) => {
      state.contractorByArea.records = action.payload;
    });
    builder.addCase(fetchContractorByArea.rejected, (state, action) => {
      state.contractorByArea.loading = false;
      state.contractorByArea.error = action.error;
    });

    builder.addCase(getLatestDetailData.pending, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = true;
    });
    builder.addCase(getLatestDetailData.fulfilled, (state, action) => {
      const defData = [
        { code: "waste", paramater: "Waste Inpit", total: 0 },
        { code: "waste", paramater: "Waste Outpit & Project", total: 0 },
        { code: "totalwaste", paramater: "Total Waste", total: 0, isHeader: true },
        { code: "coal", paramater: "Coal Getting", total: 0 },
        { code: "srInpit", paramater: "SR Inpit", total: 0, isHeader: true },
        { code: "srAll", paramater: "SR All", total: 0, isHeader: true },
      ];
      let dtTable = (action.payload?.data?.dailyPlan?.dataWasteRemoval || defData).concat(
        action.payload?.data?.dailyPlan?.totalWasteRemoval || []
      ).concat(
        action.payload?.data?.dailyPlan?.dataCoalGetting || []
      ).concat(
        action.payload?.data?.dailyPlan?.dataSRInpit || []
      ).concat(
        action.payload?.data?.dailyPlan?.dataSRAll || []
      )
      state.shortTermPlanningDailyDetail.loading = false;
      state.shortTermPlanningDailyDetail.defaultTable = dtTable;
    });
    builder.addCase(getLatestDetailData.rejected, (state, action) => {
      state.shortTermPlanningDailyDetail.loading = false;
      state.shortTermPlanningDailyDetail.error = 'Invalid get data';
    });
  },
});

export const {
  clearShortTermPlanningDailyDetail,
  createColumnShortPlanningDailyDetail,
  onChangeYearNew,
  onChangeTableMonthlyNew,
  counterDataTableNew,
  createDailyDataCustomNew,
  clearDailyTableNew,
  addUploadDataTableMonthlyNew,
  resetShortTermPlanningDailyDetail,
} = shortTermPlanningDaily.actions;

export const shortTermPlanningDailySelector = (state) =>
  state.shortTermPlanningDaily.shortTermPlanningDaily;
export const shortTermPlanningDailyDetailSelector = (state) =>
  state.shortTermPlanningDaily.shortTermPlanningDailyDetail;
export const shortTermPlanningDailyDetailColumn = (state) =>
  state.shortTermPlanningDaily.shortTermPlanningDailyDetailColumn;
export const versionListSelectorNew = (state) =>
  state.shortTermPlanningDaily.versionList;
export const contractorByArea = (state) =>
  state.payload.contractorByArea;

export default shortTermPlanningDaily.reducer;
